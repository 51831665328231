import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { tennisDataService } from '../services/TennisDataService'
import SelectionButtons from '../components/SelectionButtons';
import Spinner from '../components/Spinner';
import { Link } from 'react-router-dom';
import { logEvent } from "firebase/analytics";
import { analytics } from '../App';

const ProRanking = () => {
    const [selected, setSelected] = useState('ATP');

    useEffect(() => {
        logEvent(analytics, "ranking_view");
    }, []);

    const {data: atp, isError: atpError, isLoading: atpLoading} = useQuery({
        queryFn: async () => await tennisDataService.getAtpRanking(),
        queryKey: ["atp"],
        retry: 2,
    })

    const {data: wta, isError: wtaError, isLoading: wtaLoading} = useQuery({
        queryFn: async () => await tennisDataService.getWtaRanking(),
        queryKey: ["wta"],
        retry: 2,
    })

    if (wtaLoading && atpLoading) {
        return (
            <Spinner />
        )
    }

  return (
    <div className='text-white space-y-2 pl-1 pr-1'>
      <div className='md:grid md:grid-cols-4 pb-4'>
      <SelectionButtons labels={['ATP', 'WTA']} onSelect={setSelected} initialSelection={selected} />
      </div>

      {
        selected == "ATP" ? (
            atp?.map((rank) => (
                <Item key={rank?.id} rank={rank} />
            ))
        ) : (
            wta?.map((rank) => (
                <Item key={rank?.id} rank={rank} />
            ))
        )
      }
    </div>
  )
}

const Item = ({rank}) => {
    return (
        <Link to={`/player/${rank?.team?.id}`}>
            <div className='flex justify-between pl-1 pr-1'>
                <div className='flex justify-center items-center space-x-3'>
                    <p>{rank?.ranking}.</p>
                    <img className='h-6' src={`https://flagsapi.com/${rank?.team?.country?.alpha2}/flat/64.png`} alt="" />
                    <p>{rank?.rowName}</p>
                </div>

                <p>{rank?.points}</p>
            </div>
        </Link>
    )
}

export default ProRanking
