import { tennisDataService } from '../services/TennisDataService';
import { Link, useParams } from 'react-router-dom';
import Event from '../components/Event';
import TournamentHeader from '../components/TournamentHeader';
import { useQuery } from '@tanstack/react-query';
import Spinner from '../components/Spinner';
import SpinnerDefault from '../components/SpinnerDefault';
import { helperService } from '../services/HelperService';
import PlayerImage from '../components/PlayerImage';
import { useEffect } from 'react';
import { logEvent } from "firebase/analytics";
import { analytics } from '../App';


const PlayerDetailView = () => {
    const params = useParams();

    useEffect(() => {
        logEvent(analytics, "player_detailed_view");
    }, []);

    const {data: team, isLoading: isLoadingTeam} = useQuery({
        queryFn: async () => await tennisDataService.getTeamDetails(params.playerId),
        queryKey: [params.playerId + "teamDetails"],
        retry: 2
    })

    const {data: events, isLoading: isLoadingEvents} = useQuery({
        queryFn: async () => await tennisDataService.getTeamEvents(params.playerId),
        queryKey: [params.playerId + "events"],
        retry: 2
    })

    const {data: prevTournaments, isLoading: isLoadingPrevTournaments, isError: prevError} = useQuery({
        queryFn: async () => await tennisDataService.getTeamPrevTournaments(params.playerId),
        queryKey: [params.playerId + "prev"],
        retry: 2
    })

    if (isLoadingTeam) {
        return (
            <Spinner />
        )
    }

  return (
    <div className='text-white space-y-5'>
        <div className='text-center flex flex-col justify-center items-center space-y-2'>
            <p>*{helperService.getFormattedDate(team?.playerTeamInfo?.birthDateTimestamp, false)} {team?.playerTeamInfo?.birthplace} </p>
            <PlayerImage team={team} className={'h-40 w-40 text-7xl'} />
            {/* <img className='h-40 w-40 rounded-full' src={`https://live.scorego-app.com/player_images/${team?.id}.png`} alt="" /> */}
            <div className='flex justify-center items-center space-x-4'>
                <p className='text-2xl font-bold'>{team?.name}</p>
                <img className='h-8' src={`https://flagsapi.com/${team?.country?.alpha2}/flat/64.png`} alt="" />
            </div>
        </div>

        <InfoListHorizontal details={team?.playerTeamInfo} />

        {
            !isLoadingPrevTournaments && !prevError && <PrevTournamentsList tournaments={prevTournaments} />
        }

        <div className='pt-2'></div>

        {
            !isLoadingEvents ? (
                tennisDataService.getHeadersOfEventList(events).map((header) => (
                    <>
                        <TournamentHeader key={header.id} tournament={header} />
                        {
                            tennisDataService.getEventsForHeader(header, events, "All").map((event) => (                        
                                <Event key={event.id} event={event} />
                            ))
                        }
                    </>
                ))
            ) : (
                <SpinnerDefault />
            )
        }
    </div>
  )
}

export default PlayerDetailView

const PrevTournamentsList = ({tournaments}) => {
    const Item =({tournament}) => {
        return (
            <Link to={`/tournament/${tournament?.id}`}>
                <div className='p-2 flex-col justify-center items-center bg-darkAccent rounded-lg space-x-1'>
                <img className='h-12 w-12 object-contain' src={`https://live.scorego-app.com/tournament_images/${tournament?.id}.png`} alt="" />

                    <div className='whitespace-nowrap overflow-hidden overflow-ellipsis'>
                        <p className='font-bold'>{tournament?.name}</p>
                        {/* <IoTennisball size={22}/> */}
                        <p>{tournament?.round}</p>
                    </div>
                </div> 
            </Link>
        )
    }

    return (
        <div className='flex space-x-2 overflow-x-scroll hide-scroll-bar m-2'>
            {
                tournaments.map((tournament) =>(
                    <Item key={tournament?.id} tournament={tournament} />
                ))
            }
            
        </div>
    )    
}

const InfoListHorizontal = ({details}) => {
    const Item =({title, subtitle}) => {
        return (
            subtitle && (
                <div className='p-5 bg-darkAccent flex flex-col items-center justify-center rounded-lg'>
                    {/* <IoTennisball size={22}/> */}
                    <p className='font-bold whitespace-nowrap overflow-hidden overflow-ellipsis'>{title}</p>
                    <p className='whitespace-nowrap overflow-hidden overflow-ellipsis'>{subtitle}</p>
                </div>
            )
        )
    }

    return (
        <div className='flex space-x-2 overflow-x-scroll hide-scroll-bar m-2'>
            {details?.currentRanking && <Item  title={"Ranking"} subtitle={"#" + details?.currentRanking} />}
            {details?.height && <Item  title={"Height"} subtitle={details?.height + "m"}/>}
            
            {details?.weight && <Item  title={"Weight"} subtitle={details?.weight + "kg"}/>}

            <Item  title={"Hand"} subtitle={details?.plays}/>
            <Item  title={"Turned Pro"} subtitle={details?.turnedPro}/>
            {details?.prizeCurrentRaw && <Item  title={"Prize Money Season"} subtitle={details?.prizeCurrentRaw?.value.toLocaleString() + " " + details?.prizeCurrentRaw?.currency}/>}
            {details?.prizeTotalRaw && <Item  title={"Prize Money Total"} subtitle={details?.prizeTotalRaw?.value.toLocaleString() + " " + details?.prizeTotalRaw?.currency}/>}
            <Item  title={"Residence"} subtitle={details?.residence}/>
            
        </div>
    )    
}