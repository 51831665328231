class HelperService {
    getFormattedDate = (timestamp, showHours = true) => {
        const d = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    
        const year = d.getUTCFullYear(); // Get year from timestamp (UTC)
        const month = String(d.getUTCMonth() + 1).padStart(2, '0'); // Get month from timestamp (months start at 0, UTC)
        const day = String(d.getUTCDate()).padStart(2, '0'); // Get day from timestamp (UTC)
        const hours = String(d.getUTCHours()).padStart(2, '0'); // Get hours from timestamp (UTC)
        const minutes = String(d.getUTCMinutes()).padStart(2, '0'); // Get minutes from timestamp (UTC)
    
        const adjustedDate = new Date(Date.UTC(year, d.getUTCMonth(), day, hours, minutes)); // Create a new Date object with UTC values
        const currYear = new Date().getFullYear(); // Get current year
    
        const adjustedHours = String(adjustedDate.getHours()).padStart(2, '0'); // Get adjusted hours
        const adjustedMinutes = String(adjustedDate.getMinutes()).padStart(2, '0'); // Get adjusted minutes
    
        if (showHours) {
            return currYear === year ? `${day}.${month}, ${adjustedHours}:${adjustedMinutes}` : `${day}.${month}.${year}, ${adjustedHours}:${adjustedMinutes}`;
        } else {
            return currYear === year ? `${day}.${month}` : `${day}.${month}.${year}`;
        }
    };
    
    
    
    

    checkIfImageExists(url) {
        return new Promise((resolve, reject) => {
            var image = new Image();
    
            image.onload = () => resolve(true); // Image loaded successfully
            image.onerror = () => resolve(false); // Image failed to load
    
            image.src = url;
        });
    }

    isInProduction() {
        return process.env.NODE_ENV === 'production';
    }
}

export const helperService = new HelperService();