import React, { useEffect, useState } from 'react'
import TournamentHeader from '../components/TournamentHeader'
import Event from '../components/Event'
import { tennisDataService } from '../services/TennisDataService';
import SelectionButtons from '../components/SelectionButtons';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Spinner from '../components/Spinner';
import SpinnerDefault from '../components/SpinnerDefault';
import NoContent from '../components/NoContent';
import PlayerImage from '../components/PlayerImage';
import { logEvent } from "firebase/analytics";
import { analytics } from '../App';

const EventDetailView = () => {
    const params = useParams();
    
    useEffect(() => {
        logEvent(analytics, "detailed_event_view");
    }, []);

    const {data: event, isLoading: isLoadingEvent} = useQuery({
        queryFn: () => tennisDataService.getEventDetails(params.eventId),
        queryKey: [params.eventId + "event"],
        retry: 2
    })

    const {data: statistics, isLoading: isLoadingStatistics, isError} = useQuery({
        queryFn: async () => await tennisDataService.getEventStatistics(params.eventId),
        queryKey: [params.eventId + "statistics"],
        retry: 2,
        enabled: event?.status?.type !== "notstarted"
    })

    if (isLoadingEvent) {
        return (
            <Spinner />
        )
    }

  return (
    <div className='text-white space-y-3'>
      <TournamentHeader tournament={event?.tournament} />
      <Event event={event} />
      <VS homeTeam={event?.homeTeam} awayTeam={event?.awayTeam} />
      {
        isError ? (
            <NoContent />
        ) : (
            !isLoadingStatistics ? <Statistics statistics={statistics} /> : <SpinnerDefault />
        )
      }
    </div>
  )
}

export default EventDetailView

const Statistics = ({statistics}) => {
    const [selection, setSelection] = useState("ALL");
    return (
        <div className='pt-2 space-y-2'>
            <p className='text-xl font-bold '>Statistics</p>
            {
                <div className='md:grid md:grid-cols-3'><SelectionButtons labels={statistics?.map(stat => stat?.period)} onSelect={setSelection} initialSelection={selection} /></div>
            }

            <div className='pt-2'>
                {
                    statistics ? statistics[statistics.findIndex(elem => elem.period === selection)].groups.map((group) => (
                        <>
                            <p className='bg-darkAccent pl-2 font-bold'>{group?.groupName}</p>
                            {
                                group?.statisticsItems?.map((item) => (
                                    <div className='flex justify-between pl-2 pr-2'>
                                        <p>{item.home}</p>
                                        <p>{item.name}</p>
                                        <p>{item.away}</p>
                                    </div>
                                ))
                            }
                        </>
                    )) : <p>adsf</p>
                }
            </div>
        </div>
    )
}

const VS = ({homeTeam, awayTeam}) => {
    return (
        <div className='rounded-lg flex p-5 bg-darkAccent justify-center items-center space-x-5'>
            <Team team={homeTeam} />
            <p className='font-bold'>VS.</p>
            <Team team={awayTeam} />
        </div>
    )
}

const Team = ({team}) => {
    return (
        <Link to={`/player/${team?.id}`}>
            <div className='flex flex-col items-center justify-center'>
                <PlayerImage team={team} className={'w-14 h-14 text-3xl'} />
                <div className='flex justify-center items-center space-x-2'>
                    <p>{team?.name}</p>
                    <img className='h-5' src={`https://flagsapi.com/${team?.country?.alpha2}/flat/64.png`} alt="" />
                </div>
            </div>
        </Link>
    )
}
