import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dropdown({selected, selections, func}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-main px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset hover:bg-gray-50">
          {selected}
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-black" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1 text-black">
                <>
                    {
                        selections.map((sel) => (
                            <Menu.Item key={sel}>
                                {({ active }) => (
                                    <button key={sel} className='m-2' onClick={() => {func(sel)}}>{sel}</button>
                                )}
                            </Menu.Item>
                        ))
                    }
                </>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}



