import React, {useEffect, useContext} from 'react'
import MatchesView from './MatchesView'
import NavBar from '../components/NavBar'
import { TennisDataContext } from '../contexts/TennisDataProvider';
import TournamentOverview from './TournamentOverview';
import ReactGA from 'react-ga';
import AdsComponent from '../components/Ads/AdsComponent';
import AdsComponentHorizontal from '../components/Ads/AdsComponentHorizontal';


const HomeView = () => {

  const cardStyle = 'md:block md:p-1 pt-3 md:border-2 md:border-secondDarkAccent md:rounded-lg h-fit'
  return (
    <div>      
      {/* <AdsComponent dataSlot="4269872675" /> */}

    <div className='md:grid md:grid-cols-3 md:gap-2'>
      <div className={`md:col-span-2 ${cardStyle}`}><MatchesView /></div>

      <div className='space-y-4'>
        <div className={`hidden ${cardStyle}`}><TournamentOverview /></div>
        <>
        <AdsComponent dataSlot="3334729790" />        
        </>
      </div>
      
    </div>
      
    </div>    
  )
}

export default HomeView