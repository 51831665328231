import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IoIosTennisball } from "react-icons/io";
import { helperService } from '../services/HelperService';

const TournamentHeader = ({tournament}) => {
  const [isThere, setIsThere] = useState(true);
  const url = `https://live.scorego-app.com/tournament_images/${tournament?.uniqueTournament?.id}.png`;
  useEffect(() => {
    const is = helperService.checkIfImageExists(url);
    setIsThere(is);
  }, []);

  return (
    <Link to={`/tournament/${tournament?.uniqueTournament?.id}`}>
        <div className='flex justify-between items-center bg-darkAccent p-2'>
            <div className='flex space-x-2'>
                {
                  isThere ? <img className='h-5 w-5 object-cover' src={url} alt="" /> : <div className='h-5 w-5 flex flex-col items-center justify-center'><IoIosTennisball size={24} color='#ccff00' /></div>
                }
                <p className='font-bold'>{tournament?.uniqueTournament?.name}</p>
            </div>

            <p className='p-1 text-xs font-bold'>{tournament?.category?.name}</p>
        </div>
    </Link>       
  )
}
export default TournamentHeader 