import React, { useContext, useEffect, useState } from 'react'
import Event from '../components/Event';
import TournamentHeader from '../components/TournamentHeader';
import { TennisDataContext } from '../contexts/TennisDataProvider';
import SelectionButtons from '../components/SelectionButtons';
import { tennisDataService } from '../services/TennisDataService';
import Dropdown from '../components/Dropdown';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Spinner from '../components/Spinner';
import NoContent from '../components/NoContent';

const MatchesView = () => {
    const [selected, setSelected] = useState('All');
    const [selectedTour, setSelectedTour] = useState("ATP");
    const {liveEvents} = useContext(TennisDataContext);
    const queryClient = useQueryClient();

    const {data: allEvents, isLoading} = useQuery({
        queryFn: () => tennisDataService.getAllEvents(),
        queryKey: ["allEvents"],
        retry: 2,
    })

    useEffect(() => {
        async function x() {
            return await tennisDataService.getLiveEvents();
        }     
        async function updateLiveData() {
            const live = await x();
            console.log(live);

            queryClient.setQueryData(['allEvents'],
            (oldData) => oldData.map(event1 => {
                let event2 = live.find(event2 => event2.id === event1.id);
            
                if (event2) {
                    return {...event1, ...event2};
                } else {
                    return event1;
                }
            })
            )
        }   
        
        const i = setInterval(async () => {
            updateLiveData()

        }, 10000);

        return () => {
            clearInterval(i);
        }
        
    }, [])

    if (isLoading) {
        return (
            <Spinner />
        )
    }


  return (
    <div className='text-white'>
        <div className='flex items-center justify-between m-2'>
            <SelectionButtons labels={['All', 'Live', 'Scheduled', 'Completed']} onSelect={setSelected} initialSelection={selected} />
            <Dropdown selected={selectedTour} selections={['ATP', 'WTA', 'Chall', "ITF"]} func={setSelectedTour} />
        </div>
        {/* <SelectionButtons labels={['ATP', 'WTA', 'Challenger', "ITF"]} onSelect={setSelectedTour} initialSelection={selectedTour}/> */}
        {
            tennisDataService.getHeadersOfEventList(tennisDataService.getAllEventsWithType(allEvents, selected), selectedTour).length == 0 && <NoContent />
        }
        {
            tennisDataService.getHeadersOfEventList(tennisDataService.getAllEventsWithType(allEvents, selected), selectedTour).map((header, index) => (
                <>
                    <TournamentHeader key={index} tournament={header} />
                    {
                        tennisDataService.getEventsForHeader(header, allEvents, selected).map((event, index) => (                        
                            <Event key={index} event={event} />
                        ))
                    }
                </>
            ))
        }
    </div>
  )
}

export default MatchesView

  
