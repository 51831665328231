

class TennisDataService {
    static options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

    getTournamentDetails = async (id) => {
        const options = {
            method: 'GET',
            headers: {
              'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
              'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
            }
          };
        
        const url = `https://tennisapi1.p.rapidapi.com/api/tennis/tournament/${id}/info`;

        const response = await fetch(url, options);
            const result = await response.json();
            console.log(result.meta);
            return result.meta;
    }

    getTournamentSeasons = async (id) => {
      const url = `https://tennisapi1.p.rapidapi.com/api/tennis/tournament/${id}/seasons`;
      console.log(url);
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };
      try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result.seasons;
      } catch (error) {
        console.error(error);
      }
    }

    getTournamentLastEvents = async (id, season, page = 0, lastEvents) => {
      const url = `https://tennisapi1.p.rapidapi.com/api/tennis/tournament/${id}/season/${season}/events/last/${page}`;
      console.log(url);
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (lastEvents?.events.length > 0) {
          return {
            events: lastEvents?.events.concat(result?.events?.reverse()),
            hasNextPage: result?.hasNextPage
          };
        } else {
          return {
            events: result?.events?.reverse(),
            hasNextPage: result?.hasNextPage
          };
        }
      } catch (error) {
        console.error(error);
      }
    }

    getTournamentNextEvents = async (id, season, page = 0, nextEvents) => {
      const url = `https://tennisapi1.p.rapidapi.com/api/tennis/tournament/${id}/season/${season}/events/next/${page}`;
      console.log(url);
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        
        if (nextEvents?.events.length > 0) {
          return {
            events: nextEvents?.events.concat(result?.events?.reverse()),
            hasNextPage: result?.hasNextPage
          };
        } else {
          return {
            events: result?.events?.reverse(),
            hasNextPage: result?.hasNextPage
          };
        }
      } catch (error) {
        console.error(error);
      }
    }

    getTournamentCategorySchedules = async (id) => {

      const url = `https://tennisapi1.p.rapidapi.com/api/tennis/tournament/all/category/${id}`;
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };
      
      try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result;
      } catch (error) {
        console.error(error);
      }
    }

    getTournamentCategories = async () => {

      const url = 'https://tennisapi1.p.rapidapi.com/api/tennis/tournament/categories';
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result.categories;
      } catch (error) {
        console.error(error);
      }
    }

    getCategoryTournaments = async (id) => {

      const url = `https://tennisapi1.p.rapidapi.com/api/tennis/tournament/all/category/${id}`;
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result;
      } catch (error) {
        console.error(error);
      }
    }

    getEventSchedules = async () => {
      const today = new Date();
      const month = today.getMonth()+1;
      const year = today.getFullYear();
      const day = today.getUTCDate();
      const url = `https://tennisapi1.p.rapidapi.com/api/tennis/events/${day}/${month}/${year}`;
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result.events;
      } catch (error) {
        console.error(error);
      }
    }

    getActiveTournamentsForCategory = (category, eventsArr) => {
      var result = [];
    
      // Use filter with a return statement
      const filtered = eventsArr?.filter((event) => {
        return event?.tournament?.category?.name?.includes(category);
      });
    
      // Use some() to check if the tournament already exists in the result
      filtered?.forEach((event) => {
        if (!result.some(tournament => tournament?.id == event?.tournament?.id)) {
          result.push(event?.tournament);
        }
      });
    
      console.log(result.length);
      return result;
    }

    getLiveEvents = async () => {
      const url = 'https://tennisapi1.p.rapidapi.com/api/tennis/events/live';
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };
      
      try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result.events;
      } catch (error) {
        console.error(error);
      }
    }

    getEventStatistics = async (id) => {
      const fetch = require('node-fetch');

      const url = `https://tennisapi1.p.rapidapi.com/api/tennis/event/${id}/statistics`;
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        console.log(result.statistics);
        return result.statistics;
      } catch (error) {
        console.error(error);
      }
    }

    getEventDetails = async (id) => {
      const fetch = require('node-fetch');

      const url = `https://tennisapi1.p.rapidapi.com/api/tennis/event/${id}`;
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result.event;
      } catch (error) {
        console.error(error);
      }
    }

    getTeamDetails = async (id) => {

      const url = `https://tennisapi1.p.rapidapi.com/api/tennis/team/${id}`;
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        console.log(result.team);
        return result.team;
      } catch (error) {
        console.error(error);
      }
    }

    getTeamPrevTournaments = async (id) => {

      const fetch = require('node-fetch');

      const url = `https://tennisapi1.p.rapidapi.com/api/tennis/team/${id}/tournaments/last`;
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result.uniqueTournaments.reverse();
      } catch (error) {
        console.error(error);
      }
    }

    getTeamNextEvents = async (id) => {

      const url = `https://tennisapi1.p.rapidapi.com/api/tennis/team/${id}/events/next/0`;
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result.events;
      } catch (error) {
        console.error(error);
      }
    }

    getTeamLastEvents = async (id) => {

      const url = `https://tennisapi1.p.rapidapi.com/api/tennis/team/${id}/events/previous/0`;
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result.events;
      } catch (error) {
        console.error(error);
      }
    }

    getTeamEvents = async (id) => {
      const next = await this.getTeamNextEvents(id);
      const last = await this.getTeamLastEvents(id);
      // return last.reverse();
      if (next) {
        return last.reverse().concat(next); 
      } else {
        return last.reverse();
      }
    }

    getAtpRanking = async () => {

      const url = 'https://tennisapi1.p.rapidapi.com/api/tennis/rankings/atp';
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result.rankings;
      } catch (error) {
        console.error(error);
      }
    }

    getWtaRanking = async () => {

      const url = 'https://tennisapi1.p.rapidapi.com/api/tennis/rankings/wta';
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result.rankings;
      } catch (error) {
        console.error(error);
      }
    }



    getAllEvents = async () => {
      const eventSchedules = await this.getEventSchedules();
      // const liveEvents = await this.getLiveEvents();

      return eventSchedules;
    }

    getHeadersOfEventList = (events, tour) => {
      const res = [];

      if (tour) {
        events.forEach((event) => {
          var tournament = event.tournament;
          const hasTour = tournament?.category?.name?.toLowerCase().includes(tour.toLowerCase());
          // console.log(tournament);
          if (!res.some((tour) => tour.id === tournament.id) && hasTour) {
            res.push(tournament);
          }
        })
      } else {
        events.forEach((event) => {
          var tournament = event.tournament;
          // console.log(tournament);
          if (!res.some((tour) => tour.id === tournament.id)) {
            res.push(tournament);
          }
        })
      }

      return res;
    }

    getAllEventsWithType = (events, typeOfEvent) => {
      const res = [];

      events.forEach((event) => {
        const type = event?.status?.type
          if (typeOfEvent === "All") {
            res.push(event);
          } else if (typeOfEvent === "Live") {
            if (type === "inprogress") {
              res.push(event);
            }
          } else if (typeOfEvent === "Scheduled") {
            if (type !== "finished" && type !== "inprogress") {
              res.push(event);
            }
          } else if (typeOfEvent === "Completed") {
            if (type !== "inprogress" && type !== "notstarted") {
              res.push(event);
            }
          }
        })

      return res;
    }

    getEventsForHeader = (tournament, events, typeOfEvent) => {
      const res = [];

      events.forEach((event) => {
        if (event.tournament.id === tournament.id) {
          const type = event?.status?.type
          if (typeOfEvent === "All") {
            res.push(event);
          } else if (typeOfEvent === "Live") {
            if (type === "inprogress") {
              res.push(event);
            }
          } else if (typeOfEvent === "Scheduled") {
            if (type !== "finished" && type !== "inprogress") {
              res.push(event);
            }
          } else if (typeOfEvent === "Completed") {
            if (type !== "inprogress" && type !== "notstarted") {
              res.push(event);
            }
          }
        }
      })
      return res.reverse();
    }

    search = async (searchText) => {
      const url = `https://tennisapi1.p.rapidapi.com/api/tennis/search/${searchText}`;
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
          'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        return result.results;
      } catch (error) {
        console.error(error);
      }
    }

    
}

export const tennisDataService = new TennisDataService();