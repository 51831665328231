import React, {useState, useSearchParams, useContext, useEffect} from 'react'
import Event from '../components/Event';
import { IoTennisball } from "react-icons/io5";
import SelectionButtons from '../components/SelectionButtons';
import { useParams } from 'react-router-dom';
import { TennisDataContext } from '../contexts/TennisDataProvider';
import { tennisDataService } from '../services/TennisDataService';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import Spinner from '../components/Spinner';
import SpinnerDefault from '../components/SpinnerDefault';
import NoContent from '../components/NoContent';
import TournamentImage from '../components/TournamentImage';
import { logEvent } from "firebase/analytics";
import { analytics } from '../App';

const TournamentView = () => {
    const params = useParams();
    const [selectedValue, setSelectedValue] = useState('Completed');
    const buttonLabels = ["Scheduled", "Completed"];    
    const [lastEventPage, setLastEventPage] = useState(0);
    const [nextEventPage, setNextEventPage] = useState(0);

    useEffect(() => {
        logEvent(analytics, "detailed_event_view");
    }, []);
 
    const {data: tournamentDetails, isLoading: isLoadingTournamentDetails} = useQuery({
        queryFn: async () => await tennisDataService.getTournamentDetails(params.tournamentId),
        queryKey: [params.tournamentId + "details"],
        retry: 2
    })

    const {data: seasons, isLoading: isLoadingSeasons} = useQuery({
        queryFn: async () => await tennisDataService.getTournamentSeasons(params.tournamentId),
        queryKey: [params.tournamentId + "seasons"],
        retry: 2
    })
    
    const _getTournamentLastEvents = async () => await tennisDataService.getTournamentLastEvents(params.tournamentId, seasons[0].id, lastEventPage, tournamentLastEvents);
    const {data: tournamentLastEvents, isLoading: isLoadingTournamentLastEvents, isError: errorLast} = useQuery({
        queryFn: _getTournamentLastEvents,
        queryKey: [params.tournamentId + "lastEvents", lastEventPage],
        placeholderData: keepPreviousData,
        retry: 2,
        enabled: !!seasons
    })
    
    const x = () => {
        setLastEventPage((prev) => prev + 1);
    }

    const y = () => {
        setNextEventPage((prev) => prev + 1);
    }
    
    const _getTournamentNextEvents = async () => await tennisDataService.getTournamentNextEvents(params.tournamentId, seasons[0].id, nextEventPage, tournamenNextEvents);
    const {data: tournamenNextEvents, isLoading: isLoadingTournamentNextEvents, isError: errorNext} = useQuery({
        queryFn: _getTournamentNextEvents,
        queryKey: [params.tournamentId + "nextEvents", nextEventPage],
        placeholderData: keepPreviousData,
        retry: 2,
        enabled: !!seasons
    })

    // useEffect( () => {        
    //     if (selectedValue === "Scheduled") {
    //         refetch();
    //     }
    // }, [selectedValue]);


    if (isLoadingTournamentDetails) {
        return (
            <Spinner />
        )
    }
  return (
    <div className='text-white text-center space-y-4 '>
    
        <div className='text-center flex flex-col justify-center items-center space-y-2'>
            {/* <img className='h-32 w-32 rounded-lg p-2 bg-darkAccent' src={`https://api.sofascore.app/api/v1/unique-tournament/${tournamentDetails?.uniqueTournament?.id}/image/dark`} alt="" /> */}
            <TournamentImage tournamentDetails={tournamentDetails} className='h-32 w-32' />
            <p className='text-2xl font-bold'>{tournamentDetails?.uniqueTournament?.name}</p>
            {/* <p className=''>12.5 - 20.5</p> */}
            <p className='p-1 pr-3 pl-3 bg-darkAccent rounded-full font-bold'>{tournamentDetails?.category}</p>
        </div>

        <InfoListHorizontal details={tournamentDetails} />
        <div className='md:grid md:grid-cols-3'><SelectionButtons labels={buttonLabels} onSelect={setSelectedValue} initialSelection={selectedValue}/></div>      

        <div className='py-1'>
            {
                selectedValue === 'Scheduled' && (
                    errorNext ? (
                        <NoContent />
                    ) : (
                        !isLoadingTournamentNextEvents ? 
                            tournamenNextEvents?.events?.map((event) => (
                                <Event key={event.id} event={event} />
                            ),
                            
                        ) : <SpinnerDefault />
                        
                    )   
                )                
            }

            {
                selectedValue === "Completed" && (
                        
                    errorLast ? (
                        <NoContent />
                    ) : (
                        !isLoadingTournamentLastEvents ? 
                            
                        tournamentLastEvents?.events?.map((event) => (
                            
                            event?.status?.type == "finished" && <Event key={event.id} event={event} />
                            
                        )) : <SpinnerDefault />
                    )
                            
                )                
            }

            {
                selectedValue === "Completed" ? (
                    tournamentLastEvents?.hasNextPage && <button className='p-1 pl-2 pr-2 bg-darkAccent rounded-full font-bold' onClick={x} >more</button>

                ) : (
                    tournamenNextEvents?.hasNextPage && <button className='p-1 pl-2 pr-2 bg-darkAccent rounded-full font-bold' onClick={y} >more</button>
                )
            }
        </div>
        
    </div>
  )
}

export default TournamentView

const InfoListHorizontal = ({details}) => {
    const Item =({title, subtitle}) => {
        return (
            subtitle && (
                <div className='p-5 bg-darkAccent flex flex-col items-center justify-center rounded-lg'>
                    {/* <IoTennisball size={22}/> */}
                    <p className='font-bold whitespace-nowrap overflow-hidden overflow-ellipsis'>{title}</p>
                    <p className='whitespace-nowrap overflow-hidden overflow-ellipsis'>{subtitle}</p>
                </div>
            )
        )
    }

    return (
        <div className='flex space-x-2 overflow-x-scroll hide-scroll-bar m-2'>
            <Item title={"Title Holder"} subtitle={details.titleHolderName} />
            <Item title={"City"} subtitle={details.cityName}/>
            <Item title={"Type"} subtitle={details.category}/>
            <Item title={"Prize Money"} subtitle={details.tournamentPrizeMoney?.toLocaleString()}/>
            <Item title={"Surface"} subtitle={details.surfaceType}/>
            <Item title={"Category"} subtitle={details.category}/>
            
        </div>
    )    
}
