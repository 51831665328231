import React from 'react'
import { IoIosTennisball } from "react-icons/io";

const NoContent = () => {
  return (
    <div className='flex flex-col justify-center items-center pt-24'>
      <IoIosTennisball size={50}/>
      <p className=''>Nothing to display at the moment ☹️</p>
    </div>
  )
}

export default NoContent
