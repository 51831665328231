import { useEffect, useState } from "react";
import { helperService } from "../services/HelperService";

const PlayerImage = ({team, className}) => {
    const [isThere, setIsThere] = useState(true);
    const url = `https://live.scorego-app.com/player_images/${team?.id}.png`;

    useEffect(() => {
        async function checkImage() {
            const exists = await helperService.checkIfImageExists(url);
            setIsThere(exists);
        }
        
        checkImage();
    }, []);
    return (
        <>
            {
                isThere ? (
                    <img className={`rounded-full ${className}`} src={`https://live.scorego-app.com/player_images/${team?.id}.png`} alt="" />
                ) : (
                    <div className={` text-white font-bold ${className} bg-gray-400 flex flex-col justify-center items-center rounded-full`}>{team?.name?.charAt(0)}</div>
                )
            }
        </>
    )
}

export default PlayerImage