import React from 'react';
import { TennisDataProvider } from './contexts/TennisDataProvider';
import { NavRouter } from './components/NavRouter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const queryClient = new QueryClient();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export {analytics};

function App() {
  return (
    <div className="App max-w-[980px] mx-auto">
      <QueryClientProvider client={queryClient}>
        <TennisDataProvider>
          <NavRouter />
        </TennisDataProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
