import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { helperService } from '../services/HelperService'

const Event = ({event}) => {
    const status = event?.status?.type
  return (
    <Link to={`/event/${event?.id}`} >
      <div className='text-white p-1 space-y-1 border-b border-darkAccent pl-2 pr-1'>
        <div className='flex justify-between text-xs'>
            <p>{event?.roundInfo?.name}</p>
            {
                status != "inprogress" && (
                    status == "finished" ? (
                        <p>{status != "finished" && status != "notstarted" && status != "inprogress" ? status : helperService.getFormattedDate(event?.changes?.changeTimestamp)}</p>
                    ) : (
                        <p>{status != "finished" && status != "notstarted" && status != "inprogress" ? status : helperService.getFormattedDate(event?.startTimestamp)}</p>
                    )
                )
            }
        </div>

        <LineEvent event={event} team={event?.homeTeam} score={event?.homeScore} serve={event?.firstToServe == 1 ? true : false} winner={event?.winnerCode == 1} />
        <LineEvent event = {event} team={event?.awayTeam} score={event?.awayScore} serve={event?.firstToServe == 1 ? false : true} winner={event?.winnerCode == 2} />
     </div>
    </Link>
  )
}
  

export default Event

const LineEvent = ({event, team, score, serve, winner}) => {
    return (
        <div className={winner ? 'text-main flex justify-between' : 'flex justify-between'}>
            <div className='flex justify-center items-center space-x-2'>
                <EventImage team={team}/>

                <p>{team?.shortName}</p>
                <p className='text-xs'>{team?.ranking}</p>
                {
                    serve && !event.winnerCode && event?.status?.type == "inprogress" &&
                    <div className='rounded-full w-3 h-3 bg-main'></div>
                }
            </div>

            <div className='flex space-x-4'>
                {!event.winnerCode && <p>{score?.point}</p>}

                <div className='flex space-x-2 pr-2'>
                {
                    score?.period1 && (
                    <p className=''>
                    {score?.period1}
                    <span className='absolute text-xs'>{score?.period1TieBreak}</span>
                    </p>
                    )
                }

                {
                    score?.period2 && ( 
                    <p className=''>
                        {score?.period2}
                        <span className='absolute text-xs'>{score?.period2TieBreak}</span>
                    </p>
                    )
                }

                {
                    
                    score?.period3 && ( 
                    <p className=''>
                        {score?.period3}
                        <span className='absolute text-xs'>{score?.period3TieBreak}</span>
                    </p>
                    )
                }
                
                {
                    score?.period4 && ( 
                    <p className=''>
                        {score?.period4}
                        <span className='absolute text-xs'>{score?.period4TieBreak}</span>
                    </p>
                    )
                }
                
                {
                    score?.period5 && ( 
                    <p className=''>
                        {score?.period5}
                        <span className='absolute text-xs'>{score?.period5TieBreak}</span>
                    </p>
                    )
                }
                </div>
            </div>
        </div>
    )
}

const EventImage = ({team}) => {
    const [isThere, setIsThere] = useState(true);
    const url = `https://flagsapi.com/${team?.country?.alpha2}/flat/64.png`;

    useEffect(() => {
        async function checkImage() {
            const exists = await helperService.checkIfImageExists(url);
            setIsThere(exists);
        }
        
        checkImage();
    }, []);

    return (
        <>
            {
                isThere ? (
                    <img className='rounded-full w-6 h-6 mr-2' src={url} alt="" />
                ) : (
                    <div className='text-xs text-white font-bold h-5 w-5 bg-gray-400 flex flex-col justify-center items-center rounded-full'>{team?.name?.charAt(0)}</div>
                )
            }
        </>
    )
}
