import {BrowserRouter, Routes, Route, HashRouter} from 'react-router-dom';
import HomeView from '../views/HomeView';
import TournamentView from '../views/TournamentView';
import NavBar from './NavBar';
import MatchesView from '../views/MatchesView';
import { useContext, useEffect, useState } from 'react';
import { TennisDataContext } from '../contexts/TennisDataProvider';
import EventDetailView from '../views/EventDetailView.jsx';
import PlayerDetailView from '../views/PlayerDetailView';
import ProRanking from '../views/ProRanking';
import TournamentOverview from '../views/TournamentOverview';
import Calendar from '../views/Calendar';

export const NavRouter = () => {
    const [isInIframe, setIsInIframe] = useState(false);

    useEffect(() => {
        setIsInIframe(window !== window.parent);
    }, []);

    return (
        <HashRouter>
            {
                !isInIframe && <div className='pb-20'><NavBar /></div>
            }
            <Routes>
                <Route path="/" element={<HomeView />}/>
                <Route path="/matches" element={<MatchesView />}/>
                <Route path="/tournament/:tournamentId" element={<TournamentView />} />
                <Route path="/event/:eventId" element={<EventDetailView />} />
                <Route path="/player/:playerId" element={<PlayerDetailView />} />
                <Route path="/ranking/" element={<ProRanking />} />
                <Route path="/tournamentOverview" element={<TournamentOverview />} />
                <Route path="/calendar" element={<Calendar />} />
                {/* <Route path="/matches">
                    <MatchesView />
                </Route> */}
            </Routes>
        </HashRouter>
    )
}