import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { tennisDataService } from '../services/TennisDataService'
import Spinner from '../components/Spinner'
import SelectionButtons from '../components/SelectionButtons'
import { Link } from 'react-router-dom'
import NoContent from '../components/NoContent'
import TournamentImage from '../components/TournamentImage'
import { logEvent } from "firebase/analytics";
import { analytics } from '../App'

const TournamentOverview = () => {

    const [selected, setSelected] = useState("ATP");

    useEffect(() => {
        logEvent(analytics, "tournament_overview_view");
    }, []);


    const {data: tournaments, isLoading, isError } = useQuery({
        queryKey: ["events"],
        queryFn: async () => await tennisDataService.getEventSchedules(),
    })

    if (isLoading) {
        return (
            <Spinner />
        )
    }

    if (isError) {
        return (
            <NoContent />
        )
    }
  return (
    <div className='text-white space-y-4 pl-2 pr-2'>
        <p className='font-bold text-2xl'>Active Tournaments</p>
        <div className='md:grid md:grid-cols-3'>
            <SelectionButtons labels={['ATP', 'WTA', 'Challenger', 'ITF']} onSelect={setSelected} initialSelection={selected} />
        </div>

        {
            
            tennisDataService.getActiveTournamentsForCategory(selected, tournaments)?.map((tournament, index) => (
                <Item key={index} tournament={tournament}/>
            ))
        }
    </div>
  )
}

const Item = ({tournament}) => {
    return (
        <div className='flex items-center space-x-4 justify-between bg-darkAccent rounded-lg'>
            <Link to={`/tournament/${tournament?.uniqueTournament?.id}`}>
                <div className='flex items-center justify-center space-x-4'>
                    {/* <img className='h-24 w-24 p-2' src={`https://api.sofascore.app/api/v1/unique-tournament/${tournament?.uniqueTournament?.id}/image/dark`} alt="" /> */}
                    <TournamentImage tournamentDetails={tournament} className='h-24 w-24' />
                    <div>
                        <p>{tournament?.name}</p>
                        <p>{tournament?.uniqueTournament?.groundType}</p>
                    </div>
                </div>

                <p></p>
            </Link>
        </div>
    )
}

export default TournamentOverview
