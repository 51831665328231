import React, {useState} from 'react'
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'
import { Link, useLocation } from 'react-router-dom';
import logo from './logo.png';
import SearchBar from './SearchBar';

export default function NavBar() {
  const location = useLocation();
    const [nav, setNav] = useState(true)
    const handleNav = () => {
        setNav(!nav)
    };

  return (
    <div className='text-white flex justify-between items-center mx-auto max-w-[980px] h-18 px-4 bg-darkAccent w-full fixed rounded-b-lg z-10 py-3'>
        <Link to={"/"}>
            <div className='flex space-x-2 justify-center items-center'>
                <img className='h-7' src={logo} alt="" />
                <h1 className='w-full text-3xl font-bold text-main'>Live.</h1>
            </div>
        </Link>
        
      <div className='hidden md:block'><SearchBar /></div>

      <a className='md:hidden' href="https://apps.apple.com/de/app/scorego-tennis-live-scores/id1630365631"><img src="https://transmea.b-cdn.net/wp-content/uploads/2023/11/download-on-the-app-store-apple-logo-png-transparent.png" alt="" className='h-9' /></a>      

      <ul className='hidden md:flex space-x-3'>
        <Link to={"/"}>
          <li className={location.pathname == "/" ? `pr-2 pl-2 pt-1 pb-1 font-semibold border-b-2 border-main` : `pr-2 pl-2 pt-1 pb-1`}>Home</li>
        </Link>
        <Link to={"/tournamentOverview"}>
          <li className={location.pathname.includes("tournamentOverview") ? `pr-2 pl-2 pt-1 pb-1 font-semibold border-b-2 border-main` : `pr-2 pl-2 pt-1 pb-1`}>Tournaments</li>
        </Link>
        <Link to={"/ranking"}>
          <li className={location.pathname.includes("ranking") ? `pr-2 pl-2 pt-1 pb-1 font-semibold border-b-2 border-main` : `pr-2 pl-2 pt-1 pb-1`}>Ranking</li>
        </Link>

        <Link to={"/calendar"}>
          <li className={location.pathname.includes("calendar") ? `pr-2 pl-2 pt-1 pb-1 font-semibold border-b-2 border-maing` : `pr-2 pl-2 pt-1 pb-1`}>Calendar</li>
        </Link>

      </ul>

      <div onClick={handleNav} className='block  md:hidden'>
        {!nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      <div className={!nav ? 'fixed left-0 top-0 h-full w-[60%] bg-[#000300] ease-in-out duration-500' : 'fixed left-[-100%]'}>
        <h1 className='text-3xl font-bold text-main p-4'>Live.</h1>

        <div className='pl-2 pr-2 pb-2'><SearchBar /></div>

        <ul className='uppercase'>
            <Link to={"/"} onClick={handleNav}>
              <li className='p-4'>Matches {location.pathname == "/" && <div className='bg-main h-0.5 rounded-full'></div>} </li>
            </Link>
            <Link to={"/tournamentOverview"} onClick={handleNav}>
              <li className='p-4'>Tournaments {location.pathname.includes("tournamentOverview") && <div className='bg-main h-0.5 rounded-full'></div>}</li>
            </Link>
            <Link to={"/ranking"} onClick={handleNav}>
              <li className='p-4'>Ranking {location.pathname.includes("ranking") && <div className='bg-main h-0.5 rounded-full'></div>}</li>
            </Link>
            <Link to={"/calendar"} onClick={handleNav}>
              <li className='p-4'>Calendar {location.pathname.includes("calendar") && <div className='bg-main h-0.5 rounded-full'></div>}</li>
            </Link>
        </ul>

      </div>

    </div>
  )
}