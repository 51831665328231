import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { tennisDataService } from '../services/TennisDataService'
import Spinner from '../components/Spinner';
import { Link } from 'react-router-dom';
import SelectionButtons from '../components/SelectionButtons';
import { logEvent } from "firebase/analytics";
import { analytics } from '../App';

const Calendar = () => {
    const [selected, setSelected] = useState("ATP");
    const [id, setId] = useState(6);

    useEffect(() => {
        logEvent(analytics, "calendar_view");
    }, []);

    const {data: calendar, isLoading: isLoadingCalendar, isError, refetch} = useQuery({
        queryFn: async () => tennisDataService.getTournamentCategorySchedules(id),
        queryKey: ["schedules" + id],
        retry: 2,
    })

    useEffect(() => {
        newSelection();
    }, [selected]);

    function newSelection() {
        if (selected == "ATP") {
            setSelected("ATP");
            setId(3);
            refetch();
        } else if (selected == "WTA") {
            setSelected("WTA");
            setId(6);
            refetch();
        } else if (selected == "Challenger") {
            setSelected("Challenger");
            setId(72);
            refetch();
        } else {
            setSelected("ITF");
            setId(785);
            refetch();
        }
    }

    if (isLoadingCalendar) {
        return (
            <div className='text-white space-y-4'>
                <div className='md:grid md:grid-cols-3'>
                    <SelectionButtons labels={['ATP', 'WTA', 'Challenger', 'ITF']} onSelect={setSelected} initialSelection={selected} />
                </div>
                <Spinner />
            </div>
        )
    }

  return (
    <div className='text-white space-y-4'>
        <div className='md:grid md:grid-cols-3'>
            <SelectionButtons labels={['ATP', 'WTA', 'Challenger', 'ITF']} onSelect={setSelected} initialSelection={selected} />
        </div>
      {
        calendar?.groups?.map((group) => (
            
            <div className='space-y-2'>
                <p className='font-bold text-2xl p-1 pl-2 bg-darkAccent'>{group?.name}</p>

                {
                    group?.uniqueTournaments?.map((tournament) => (
                        <Item tournament={tournament} />          
                    ))
                }
            </div>
        ))
      }
    </div>
  )
}

export default Calendar

const Item = ({tournament}) => {
    return (
        <Link to={`/tournament/${tournament?.id}`}>
            <div className='py-2 flex items-center'>
                <img className='h-16 w-16 p-2' src={`https://live.scorego-app.com/tournament_images/${tournament?.id}.png`} alt="" />
                <div>
                    <p>{tournament?.name}</p>
                    <p>{tournament?.category?.name} {tournament?.tennisPoints}</p>
                </div>
            </div>
        </Link>
    )
}
