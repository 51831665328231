import { createContext, useState } from "react";

export const TennisDataContext = createContext(null);

export const TennisDataProvider = ({children}) => {
    const [liveEvents, setLiveEvents] = useState([]);

    const url = 'https://tennisapi1.p.rapidapi.com/api/tennis/events/live';
    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '788387814dmsh8417668837e8dc4p1e18d8jsnda00152682da',
        'X-RapidAPI-Host': 'tennisapi1.p.rapidapi.com'
      }
    };

    const fetchLiveEvents = async () => {
        console.log("fetchLiveEvents")
        const response = await fetch(url, options);
        const data = await response.json();
    
        console.log(data.events);
        setLiveEvents(data.events);
    }

    const value = {
        liveEvents,
        setLiveEvents,
        fetchLiveEvents,

    }

    return (
        <TennisDataContext.Provider value={value}>{children}</TennisDataContext.Provider>
    )
}