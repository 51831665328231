import React, { useEffect, useState } from 'react'
import itf from './itf.png'
import { helperService } from '../services/HelperService'
import { IoIosTennisball } from "react-icons/io";


const TournamentImage = ({tournamentDetails, className = ''}) => {
  const [isThere, setIsThere] = useState(true);
  const url = `https://live.scorego-app.com/tournament_images/${tournamentDetails?.uniqueTournament?.id}.png`;

  useEffect(() => {
    async function checkImage() {
        const exists = await helperService.checkIfImageExists(url);
        setIsThere(exists);
    }
    
    checkImage();
  }, []);

  return (
    <div>

        {
            tournamentDetails?.category?.name?.toLowerCase().includes("itf") ? (
                <img className={`${className} rounded-lg p-2 bg-darkAccent object-contain`} src={itf} alt="" />
            ) : (
              isThere ? (
                  <img className={`${className} rounded-lg p-2 bg-darkAccent`} src={url} alt="" />
                ) : (
                  <div className={`${className} flex flex-col items-center justify-center`}><IoIosTennisball size={45} color='#ccff00' /></div>
                )
                
            )
        }
    </div>
  )
}

export default TournamentImage
