import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { tennisDataService } from '../services/TennisDataService';
import Spinner from './Spinner';
import NoContent from './NoContent';
import { Link } from 'react-router-dom';
import { IoIosCloseCircle } from "react-icons/io";



function SearchBar() {
    const queryClient = useQueryClient();    
  const [searchTerm, setSearchTerm] = useState('');

  const {data: searchResults, isLoading, isError, refetch} = useQuery({
    queryKey: ["searchRes"],
    queryFn: async () => await tennisDataService.search(searchTerm),
    enabled: false
    
  });

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    // You can also perform search operations here
};

const close = () => {
    queryClient.setQueryData(["searchRes"], (data) => {
        return null;
    });
}
// Optional: Handle form submission
const handleSubmit = (event) => {
    event.preventDefault();
    // Implement your search logic here
    console.log('Searching for:', searchTerm);
    refetch();
  };

  return (
    <div className='space-y-2'>
        <form onSubmit={handleSubmit}>
            <div className='text-white flex border p-2 rounded-xl space-x-1'>
            <input className='bg-transparent w-full' type="text" name="" id="" placeholder='Search' onChange={handleInputChange} />
            <svg className='h-6 text-white'
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor">
                    <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
            </div>
        </form>

        {
            searchResults && (
                <div className='absolute w-auto p-3 rounded-lg bg-secondDarkAccent space-y-2'>
                    <div className='flex space-x-10 justify-between'>
                        <p className='text text-main font-bold pb-2'>Results</p>
                        <button onClick={close}><IoIosCloseCircle size={22} /></button>
                    </div>
                    {
                        isLoading ? (
                            <Spinner />
                        ) : (
                            isError ? (
                                <NoContent />
                            ) : (                    
                                searchResults?.map((res) => (
                                    <SearchItem item={res} />
                                ))
                            )
                        )
                    }
                </div>
            )
        }
    </div>
  );
}

export default SearchBar;

const SearchItem = ({item}) => {
    const type = item?.type;
    return (
        <Link to={type === "team" ? `/player/${item?.entity?.id}` : `/tournament/${item?.entity?.id}`}>
            <div className='pb-2'>
                <p>{item?.entity?.name}</p>
                <p className='text-xs'>{item?.entity?.category?.name}</p>
            </div>
        </Link>
    )
}