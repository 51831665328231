import React, { useEffect, useState } from 'react';

const SelectionButtons = ({ labels, onSelect, initialSelection }) => {
    const [select, setSelect] = useState(labels[0]);
    useEffect(() => (
        setSelect(initialSelection)
    ), []);
  return (
    <div className='space-x-2 flex'>
      {labels.map(label => (
        <button
          key={label} // Added a key prop here for list items
          className={select == label ? 'rounded-lg p-0.5 pr-2 pl-2 w-full bg-main text-black font-bold' : 'border rounded-lg p-0.5 pr-2 pl-2 w-full md:hover:bg-darkAccent'}
          onClick={() => {
            onSelect(label);
            setSelect(label);
          }} // Assuming onSelect uses the label
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default SelectionButtons
